<template>
  <div class="auth-window">
    <div class="auth-window__form-section auth-window__form-section_confirm">
      <div>
        <header class="auth-window__form-header auth-window__form-header_registration">
          <h3 class="auth-window__form-title">Confirm Registration</h3>
        </header>

        <div class="auth-window__form auth-window__form_register-success">
          <AppError v-if="error" :error="error"/>

          <h1 v-else-if="!confirm">Confirmation your Email</h1>

          <h1 v-else>Your email has been successfully verified.</h1>
        </div>

        <!-- <div v-else class="auth-window__form auth-window__form_register-success"> -->
        <!-- <AppError :error="error"/> -->
        <!-- <h1>The activation key you provided is invalid.</h1> -->
        <!-- </div> -->
      </div>

      <button class="action-auth-btn action-auth-btn_login" @click="handlerVerification">Ок</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import AppError from "@/components/app/root/AppError";

export default {
  components: { AppError },

  data: () => ({
    confirm: false,
    error: null
  }),

  computed: {
    ...mapGetters("auth", ["registrationErrorCode"])
  },

  methods: {
    handlerVerification() {
      // Redirect the user to the page he first tried to visit or to the login view
      this.$router.push("/login");
      // const confirmKey = {
      //   activation_key: this.$route.params.activation_key
      // };
      // this.$store.dispatch("auth/confirmEmail", confirmKey);
      // this.confirm = true;
    }
  },

  mounted() {
    const confirmKey = {
      activation_key: this.$route.params.activation_key
    };
    this.$store.dispatch("auth/confirmEmail", {
      activationToken: confirmKey,
      cb: () => {
        this.confirm = true;
        this.error = null;
      },
      cbError: err => {
        console.log(err);
        this.error = err;

        // this.error = "The activation key you provided is invalid.";
      }
    });
    // this.confirm = true;
  }
};
</script>